a {
  cursor: auto;
}

.navbar {
  text-align: center;
}

.centered {
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
}

.home-logo {
  width: 100px;
}

html {
  cursor: auto;
  font-family: -apple-system, "system-ui";
  background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.9)), url(https://res.cloudinary.com/globecloud/image/upload/v1670874961/Crypto-GTM-Launch_Blog-cover-1_hxfjwc.png);
  background-size: auto 100%;
}

body {
  font-family: -apple-system, "system-ui";
}

h1, h4, p {
  font-family: -apple-system, "system-ui";
  color: #444444;
  text-align: center;
}

* {
  font-family: -apple-system, "system-ui";
  color: #444444;
}

.icons-images {
  width: 75px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 30px 0px;
  transition-duration: 0.5s;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 10px;
}

.icons-images:hover {
  transition-duration: 0.5s;
  box-shadow: black 0px 15px 30px 0px;
}

.merit-circle-align {
  margin-top: 32px;
}
.merit-circle-card {
  width: 100%;
}
.merit-circle-wallet {
  border-radius: 20px;
  min-height: 166px;
}
.merit-circle-walletstyle {
  color: #69f5e1;
}
.merit-circle-notavailable {
  color: #d74d3e;
  font-weight: bold;
}
.merit-circle-wallet {
  text-align: center;
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(https://res.cloudinary.com/globecloud/image/upload/v1670875304/mc-full-icon_vgqkun.webp);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 30px 0px;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

