a {
  cursor: auto;
}

.navbar {
  text-align: center;
}

.centered {
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
}

.home-logo {
  width: 100px;
}

html {
  cursor: auto;
  font-family: -apple-system, "system-ui";
  background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.9)), url(https://res.cloudinary.com/globecloud/image/upload/v1670874961/Crypto-GTM-Launch_Blog-cover-1_hxfjwc.png);
  background-size: auto 100%;
}

body {
  font-family: -apple-system, "system-ui";
}

h1, h4, p {
  font-family: -apple-system, "system-ui";
  color: #444444;
  text-align: center;
}

* {
  font-family: -apple-system, "system-ui";
  color: #444444;
}

.icons-images {
  width: 75px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 30px 0px;
  transition-duration: 0.5s;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 10px;
}

.icons-images:hover {
  transition-duration: 0.5s;
  box-shadow: black 0px 15px 30px 0px;
}

.coin img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  transition: all 0.5s;
}

.coin img:hover {
  height: 60px;
  width: 60px;
  transition: all 0.5s;
}

.coincard-red {
  color: #d74d3e;
  border: 1px solid #d74d3e;
  border-radius: 5px;
  padding: 10px;
}
.coincard-green {
  color: #58a257;
  border: 1px solid #58a257;
  border-radius: 5px;
  padding: 10px;
}
.coincard-regular {
  border: 1px solid #444444;
  border-radius: 5px;
  padding: 10px;
  transition-duration: 1s;
  transition-property: all;
  max-width: 100%;
}

.coin-data {
  display: grid;
  align-content: center;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.coin-format {
  width: 30%;
  display: inline-block;
  display: flex;
  font-size: 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.coin-container {
  display: grid;
  justify-content: center;
  align-items: center;
}
.coin-HTLLTH {
  border-radius: 5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}

.sortbutton {
  background-color: #444444;
  border-radius: 5px;
  border: none;
  margin: 10px;
  padding: 10px;
  font-size: 15px;
  color: #ffffff;
  background-color: #444444;
  transition: color 0.5s, background-color 0.5s;
}

.sortbutton:hover {
  color: #444444;
  background-color: #ffffff;
  transition: color 0.5s, background-color 0.5s;
}

.homebutton {
  width: 75px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 30px 0px;
  transition-duration: 0.5s;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
}

.homebutton:hover {
  transition-duration: 0.5s;
  box-shadow: black 0px 15px 30px 0px;
}

