
.nfts {
  &-image {
    max-width: 80%;
  }
  &-container {
	margin-top: 32px;
  }
}

