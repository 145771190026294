@import "main";

h3 {
  color: $text-color;
}

table {border-spacing: 0;}
thead {
}
tfoot {

}
.lds-ellipsis div:nth-child(1) {left: 8px; animation: lds-ellipsis1 0.6s infinite;}
.lds-ellipsis div:nth-child(2) {left: 8px; animation: lds-ellipsis2 0.6s infinite;}
.lds-ellipsis div:nth-child(3) {left: 32px; animation: lds-ellipsis2 0.6s infinite;}
.lds-ellipsis div:nth-child(4) {left: 56px; animation: lds-ellipsis3 0.6s infinite;}
@keyframes lds-ellipsis1 {0% {transform: scale(0);} 100% {transform: scale(1);}}
@keyframes lds-ellipsis3 {0% {transform: scale(1);} 100% {transform: scale(0);}}

@keyframes lds-ellipsis2 {0% {transform: translate(0, 0);} 100% {transform: translate(24px, 0);}}

.table {
  &-maincolor {
    color: $text-color;
    font-size: 1.8rem;
    margin-top: 0;
    margin-bottom: 0;
  } 
  &-balanceformat {
    width: 27%;
  } 
  &-stakerewardsformat {
    width: 25%;
  } 
  &-title {
    font-size: 1rem;
    color: #888888;
  }
  &-label {
    color: #DDDDDD;
  }
}
.solanabeach {
  &-card {
    width: 100%;
  }
  &-wallet {
    border-radius: 20px;
    min-height: 166px;
  }
  &-walletstyle {
    color: $cyan-color;
  }
  &-notavailable {
    color: $loading-color;font-weight: bold;
  }
  &-wallet {
    text-align: center;
    background: linear-gradient(rgba(255,255,255,.85), rgba(255,255,255,.9)), url(https://res.cloudinary.com/globecloud/image/upload/v1670873957/solana-sol-logo_mztlk4.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: rgb(0 0 0 / 20%) 0px 15px 30px 0px;width: 90%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &-card td {
    padding: 10px; border: 0;
  }
  &-align {
    margin-top: 32px;
  }
}
.lds-ellipsis {
  display: inline-block;position: relative;
  width: 80px;height: 80px;
  }
.lds-ellipsis div {
  position: absolute;top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader-container {
  width: 88.5%;
  height: 3%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}