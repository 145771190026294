@import "main";

.coin img {
    height: calc($size-10px * 5);
    width: calc($size-10px * 5);
    border-radius: $border-radius-5px;
    transition: all 0.5s;
}
.coin img:hover {
  height: calc($size-10px * 6);
  width: calc($size-10px * 6);
  transition: all 0.5s;
}
.coincard {
  &-red {
    color: $crypto-negative-color;
    border: 1px solid $crypto-negative-color;
    border-radius: $border-radius-5px;
    padding: 10px;
  }
  &-green {
    color: $crypto-positive-color;
    border: 1px solid $crypto-positive-color;
    border-radius: $border-radius-5px;
    padding: 10px;
  }
  &-regular {
    border: 1px solid $primary-color;
    border-radius: $border-radius-5px;
    padding: 10px;
    transition-duration: 1s;
    transition-property: all;
    max-width: 100%;
  }
}
.coin {
  &-data {
    display: grid;
    align-content: $align;
    text-align: $align;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &-format {
    width: 30%;
    display:inline-block;
    display: flex;
    font-size: 15px;
    display: flex;
    align-items: $align;
    border-radius: $border-radius-5px;
  }
  &-container {
    display: grid;
    justify-content: $align;
    align-items: $align;
  }
  &-HTLLTH {
    border-radius: $border-radius-5px;
    text-align: $align;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }
}
.sortbutton {
  background-color: $primary-color;
  border-radius: $border-radius-5px;
  border: none;
  margin: $size-10px;
  padding: $size-10px;
  font-size: 15px;
  color: $secondary-color;
  background-color: $primary-color;
  transition: color 0.5s, background-color 0.5s;
}
.sortbutton:hover {
  color: $primary-color;
  background-color: $secondary-color;
  transition: color 0.5s, background-color 0.5s;
}
.homebutton {
  width: 75px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 15px 30px 0px;
  transition-duration: 0.5s;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px;
}
.homebutton:hover {
  transition-duration: 0.5s;
  box-shadow: rgb(0 0 0 / 100%) 0px 15px 30px 0px;
}