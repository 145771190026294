$font-stack: -apple-system, 'system-ui';
$primary-color: #444444;
$secondary-color: #ffffff;
$text-color: #444444;
$background-color: #ffffff;
$cursor-crosshair: auto;
$crypto-positive-color: #58a257;
$crypto-negative-color: #d74d3e;
$border-radius-5px: 5px;
$size-10px: 10px;
$align: center;

$even-table-background: #eeeeee;
$background-table-color: #FFFFFF;
$loading-color: #d74d3e;
$cyan-color: #69f5e1;

a {cursor: $cursor-crosshair;}
.navbar {text-align: center;}
.centered {text-align: center;}
::-webkit-scrollbar {
  display: none;
}
.home-logo {
  width: 100px;
}
html {
  cursor: $cursor-crosshair;
  font-family: $font-stack;
  background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.9)), url(https://res.cloudinary.com/globecloud/image/upload/v1670874961/Crypto-GTM-Launch_Blog-cover-1_hxfjwc.png);
  background-size: auto 100%;
}

body {
  font-family: $font-stack;
}
h1, h4, p {
  font-family: $font-stack;
  color: $text-color;
  text-align: center;
}
* {
    font-family: $font-stack;
    color: $primary-color;
}
.icons-images {
    width: 75px;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 25%) 0px 15px 30px 0px;
    transition-duration: 0.5s;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 10px;
}
.icons-images:hover{
  transition-duration: 0.5s;
  box-shadow: rgb(0 0 0 / 100%) 0px 15px 30px 0px;
}