@import "main";

.merit-circle {
  &-align {
    margin-top: 32px;
  }
  &-card {
    width: 100%;
  }
  &-wallet {
    border-radius: 20px;
    min-height: 166px;
  }
  &-walletstyle {
    color: $cyan-color;
  }
  &-notavailable {
    color: $loading-color;font-weight: bold;
  }
  &-wallet {
    text-align: center;
    background: linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(https://res.cloudinary.com/globecloud/image/upload/v1670875304/mc-full-icon_vgqkun.webp);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: rgb(0 0 0 / 20%) 0px 15px 30px 0px;width: 90%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
