a {
  cursor: auto;
}

.navbar {
  text-align: center;
}

.centered {
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
}

.home-logo {
  width: 100px;
}

html {
  cursor: auto;
  font-family: -apple-system, "system-ui";
  background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.9)), url(https://res.cloudinary.com/globecloud/image/upload/v1670874961/Crypto-GTM-Launch_Blog-cover-1_hxfjwc.png);
  background-size: auto 100%;
}

body {
  font-family: -apple-system, "system-ui";
}

h1, h4, p {
  font-family: -apple-system, "system-ui";
  color: #444444;
  text-align: center;
}

* {
  font-family: -apple-system, "system-ui";
  color: #444444;
}

.icons-images {
  width: 75px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 30px 0px;
  transition-duration: 0.5s;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 10px;
}

.icons-images:hover {
  transition-duration: 0.5s;
  box-shadow: black 0px 15px 30px 0px;
}

h3 {
  color: #444444;
}

table {
  border-spacing: 0;
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.table-maincolor {
  color: #444444;
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 0;
}
.table-balanceformat {
  width: 27%;
}
.table-stakerewardsformat {
  width: 25%;
}
.table-title {
  font-size: 1rem;
  color: #888888;
}
.table-label {
  color: #DDDDDD;
}

.solanabeach-card {
  width: 100%;
}
.solanabeach-wallet {
  border-radius: 20px;
  min-height: 166px;
}
.solanabeach-walletstyle {
  color: #69f5e1;
}
.solanabeach-notavailable {
  color: #d74d3e;
  font-weight: bold;
}
.solanabeach-wallet {
  text-align: center;
  background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.9)), url(https://res.cloudinary.com/globecloud/image/upload/v1670873957/solana-sol-logo_mztlk4.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 30px 0px;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.solanabeach-card td {
  padding: 10px;
  border: 0;
}
.solanabeach-align {
  margin-top: 32px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader-container {
  width: 88.5%;
  height: 3%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

